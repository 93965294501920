<template>
  <div class="data-map-package-launcher">
    <ct-centered-spinner v-if="loading" />

    <div v-else>
      <div v-if="environment === 'production'">
        <span
          class="hidden"
          aria-hidden="true"
        >
          {{ packageVersion }}
        </span>
        <div
          v-for="resource in adminPortalUrls"
          :key="resource.prod"
        >
          <span
            class="hidden"
            aria-hidden="true"
          >
            {{ resource.prod }}
          </span>
          <span
            class="hidden"
            aria-hidden="true"
          >
            {{ resource.staging }}
          </span>
        </div>
      </div>
      <div v-else>
        <div v-if="environment === 'development'">
          <p class="package-switch-label">
            Local Dev Version Switch
          </p>
          <b-form-select
            :value="packageVersion"
            :options="packageVersionSwitchOptions"
            class="package-switch"
            @change="loadForm"
          />
        </div>
        <div
          v-for="resource in adminPortalUrls"
          :key="resource.prod"
        >
          <a
            :href="resource.prod"
            target="_blank"
            rel="noopener noreferrer"
            class="admin-portal-link"
          >
            {{ `Prod Mapper - ${resource.name}` }}
          </a>
          <a
            :href="resource.staging"
            target="_blank"
            rel="noopener noreferrer"
            class="admin-portal-link"
          >
            {{ `Staging Mapper - ${resource.name}` }}
          </a>
        </div>
        <hr>
        <hr>
      </div>
    </div>

    <component
      :is="dataMapFormComponent"
      v-if="currentPackageIndex !== null"
      :loading="loading"
      :validate-and-submit="validateAndSubmit"
      @validate-and-submit-result="$emit('validate-and-submit-result', $event)"
    />
  </div>
</template>

<script>
import {
  packageVersionNames,
  availablePackageVersions,
  packageVersionsModules,
  dataMapModules,
} from '@/data-map-package-helper.js'
import http from '@/http'
import { helpers } from '@core/data-map-frontend/src/helpers'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'DataMapPackageLauncher',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    DataMapForm0:      () => import('@core/data-map-frontend/src/components/Form/DataMapForm'),
    DataMapForm1:      () => import('data-map-long-term-support/src/components/Form/DataMapForm'),
    DataMapForm2:      () => import('data-map-stable/src/components/Form/DataMapForm'),
    DataMapForm3:      () => import('data-map-feature-A/src/components/Form/DataMapForm'),
    DataMapForm4:      () => import('data-map-feature-B/src/components/Form/DataMapForm'),
    DataMapForm5:      () => import('data-map-feature-C/src/components/Form/DataMapForm'),
  },
  props: {
    versions: {
      type: Array,
      required: true,
    },
    formMode: {
      type: String,
      required: true,
    },
    sourceItem: {
      type: Object,
      required: true,
    },
    reloadForm: {
      type: Boolean,
      default: false,
    },
    parentLoading: {
      type: Boolean,
      default: null,
    },
    validateAndSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: null,
      currentPackageIndex: null,
    }
  },
  computed: {
    ...mapGetters('packageVersions', [
      'packageVersion',
    ]),
    ...mapGetters('dataMap', [
      'adminPortalUrls',
    ]),
    environment() {
      if (process.env.NODE_ENV === 'production') return 'production'
      return process.env.VUE_APP_STAGING ? 'staging' : 'development'
    },
    assignedVersion() {
      const versions = this.versions
        .filter(v => {
          return [...packageVersionNames, ...availablePackageVersions].includes(v) && v !== 'Linked'
        })
        .map(v => {
          const index = packageVersionNames.indexOf(v)
          return index > -1 ? availablePackageVersions[index] : v
        })
        .filter(v => v !== 'Linked')

      const longTermSupportVersion = availablePackageVersions[1]
      return versions.length > 0 ? helpers.sortPackageVersions(versions)[0] : longTermSupportVersion
    },
    canUseLinkedPackage() {
      return this.environment === 'development' && availablePackageVersions[0] === 'Linked'
    },
    dataMapFormComponent() {
      // TODO: leave for staging test but delete before prod pr, also test on test-5
      console.log('environment', this.environment)



      return `data-map-form${this.currentPackageIndex}`
    },
    packageVersionSwitchOptions() {
      const publishedPackages = availablePackageVersions.filter(v => v !== 'Linked')
      const sortedPackages = [...new Set(helpers.sortPackageVersions(publishedPackages))]
      const options = sortedPackages.map(p => ({ text: p, value: p }))
      if (this.canUseLinkedPackage) options.unshift({ text: 'Linked', value: 'Linked' })
      let assignedOption = options.find(o => o.text === this.assignedVersion)
      if (assignedOption) assignedOption.text = `${this.assignedVersion} (Assigned)`
      return options
    },
  },
  watch: {
    reloadForm() {
      this.loadForm()
    },
    parentLoading(newValue) {
      this.loading = newValue
    },
  },
  mounted() {
    this.loadForm()
  },
  methods: {
    ...mapActions('packageVersions', [
      'packageInit',
    ]),
    ...mapActions('dataMap', [
      'buildForm',
    ]),
    async loadForm(version) {
      this.loading = true
      this.$emit('loading', true)
      this.currentPackageIndex = null

      if (!version) version = this.canUseLinkedPackage ? 'Linked' : this.assignedVersion
      const packageIndex = availablePackageVersions.indexOf(version)
      await this.swapModule('packageVersions', packageVersionsModules[packageIndex])
      await this.swapModule('dataMap', dataMapModules[packageIndex])

      await this.packageInit({ http, inAdminPortal: false, linked: version === 'Linked' })
      await this.buildForm({ formMode: this.formMode, sourceItem: this.sourceItem })

      this.currentPackageIndex = packageIndex
      this.$emit('loading', false)
      this.loading = false
    },
    async swapModule(moduleName, module) {
      this.$store.unregisterModule(moduleName)
      while (this.$store.hasModule(moduleName))
        await new Promise(resolve => setTimeout(resolve, 50))

      this.$store.registerModule(moduleName, module)
      while (!this.$store.hasModule(moduleName))
        await new Promise(resolve => setTimeout(resolve, 50))
    },
  },
}
</script>

<style lang="scss" scoped>
  .data-map-package-launcher {
    .hidden {
      display: none;
      pointer-events: none;
    }
    .package-switch-label {
      margin: 0;
    }
    .package-switch {
      width: 12rem;
      height: 3rem;
      min-height: unset !important;
    }
    .admin-portal-link {
      display: block;
      margin-top: 0.5rem;
    }
  }
</style>
