var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "data-map-package-launcher" },
    [
      _vm.loading
        ? _c("ct-centered-spinner")
        : _c("div", [
            _vm.environment === "production"
              ? _c(
                  "div",
                  [
                    _c(
                      "span",
                      {
                        staticClass: "hidden",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.packageVersion) + "\n      "
                        ),
                      ]
                    ),
                    _vm._l(_vm.adminPortalUrls, function (resource) {
                      return _c("div", { key: resource.prod }, [
                        _c(
                          "span",
                          {
                            staticClass: "hidden",
                            attrs: { "aria-hidden": "true" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(resource.prod) +
                                "\n        "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "hidden",
                            attrs: { "aria-hidden": "true" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(resource.staging) +
                                "\n        "
                            ),
                          ]
                        ),
                      ])
                    }),
                  ],
                  2
                )
              : _c(
                  "div",
                  [
                    _vm.environment === "development"
                      ? _c(
                          "div",
                          [
                            _c("p", { staticClass: "package-switch-label" }, [
                              _vm._v(
                                "\n          Local Dev Version Switch\n        "
                              ),
                            ]),
                            _c("b-form-select", {
                              staticClass: "package-switch",
                              attrs: {
                                value: _vm.packageVersion,
                                options: _vm.packageVersionSwitchOptions,
                              },
                              on: { change: _vm.loadForm },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.adminPortalUrls, function (resource) {
                      return _c("div", { key: resource.prod }, [
                        _c(
                          "a",
                          {
                            staticClass: "admin-portal-link",
                            attrs: {
                              href: resource.prod,
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(`Prod Mapper - ${resource.name}`) +
                                "\n        "
                            ),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "admin-portal-link",
                            attrs: {
                              href: resource.staging,
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(`Staging Mapper - ${resource.name}`) +
                                "\n        "
                            ),
                          ]
                        ),
                      ])
                    }),
                    _c("hr"),
                    _c("hr"),
                  ],
                  2
                ),
          ]),
      _vm.currentPackageIndex !== null
        ? _c(_vm.dataMapFormComponent, {
            tag: "component",
            attrs: {
              loading: _vm.loading,
              "validate-and-submit": _vm.validateAndSubmit,
            },
            on: {
              "validate-and-submit-result": function ($event) {
                return _vm.$emit("validate-and-submit-result", $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }